<template>
  <div class="home">
    <router-view>
      <Nav />
      <About />
      <Service />
      <ProjectSlider />
      <Team />
      <TestimonialSlider />
      <ContactForm />
      <Map />
    </router-view>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import About from "@/components/About.vue";
import Service from "@/components/Service.vue";
import ProjectSlider from "@/components/ProjectSlider.vue";
import Team from "@/components/Team.vue";
import TestimonialSlider from "@/components/TestimonialSlider.vue";
import ContactForm from "@/components/ContactForm.vue";
// import Map from "@/components/Map.vue";
// import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Nav,
    About,
    Service,
    ProjectSlider,
    Team,
    TestimonialSlider,
    ContactForm,
    // Map,
    // Footer,
  },
};
</script>
<style lang="css">

</style>
