<template>
    <section id="project" class="project-area pt-65 pb-80">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="section-title text-center pb-50">
                        <h5 class="sub-title mb-15">Featured Works</h5>
                        <h2 class="title">Projects You May Love</h2>
                    </div>
                    <!-- section title -->
                </div>
            </div>
            <!-- row -->
        </div>
        <div class="container-fluid">
            <div class="row project-active">
                <Carousel :settings="settings" :breakpoints="breakpoints" :wrap-around="true">
                    <Slide v-for="slide in sliders" :key="slide.alt">

                        <div class="col-lg-12 px-3">
                            <div class="single-project">
                                <div class="project-image wow fadeInUp" data-wow-duration="1.5s">
                                    <picture>
                                        <source :srcset="slide.srcset.webp" :alt="slide.alt" type="image/webp">
                                        <source :srcset="slide.srcset.jpg" :alt="slide.alt" type="image/jpg">
                                        <img :src="slide.src" :alt="slide.alt"/>
                                    </picture>
                                </div>
                                <div class="project-content">
                                    <a class="project-title" :href="slide.href">{{
                                        slide.title
                                        }}</a>
                                </div>
                            </div>
                        </div>

                    </Slide>

                    <template #addons>
                        <Pagination></Pagination>
                        <Navigation></Navigation>
                    </template>
                </Carousel>
            </div>
        </div>
    </section>
</template>

<script>

    import {defineComponent} from "vue";
    import {Carousel, Pagination, Slide} from "vue3-carousel";
    import "vue3-carousel/dist/carousel.css";

    export default defineComponent({
        name: "ProjectSlider",
        components: {
            Pagination,
            Carousel,
            Slide,
        },
        data: () => ({
            // carousel settings
            settings: {
                itemsToShow: 5,
                snapAlign: "start",
                centerMode: true,
                // breakpoints are mobile first
                // any settings not specified will fallback to the carousel settings
                // responsive: [
                //     {
                        breakpoints: {
                            // 325px and up
                            325: {
                                itemsToShow: 1,
                                snapAlign: "start",
                                centerMode: true,
                                containerHeight:'100%'
                            },
                            // 700px and up
                            768: {
                                itemsToShow: 3,
                                snapAlign: "start",
                                centerMode: true,
                            },
                            // 1024 and up
                            1024: {
                                itemsToShow: 5,
                                snapAlign: "start",
                            },
                        },
                //     }
                // ],
            },

            sliders: [
                {
                    title: "Home Interior Design",
                    srcset: {
                        webp: require("../assets/images/project/p-1.webp"),
                        jpg: require("../assets/images/project/p-1.jpg")
                    },
                    src: require("../assets/images/project/p-1.jpg"),
                    alt: "Project1",
                    href: "#",
                },
                {
                    title: "Home Interior Design",
                    srcset: {
                        webp: require("../assets/images/project/p-2.webp"),
                        jpg: require("../assets/images/project/p-2.jpg")
                    },
                    src: require("../assets/images/project/p-2.jpg"),
                    alt: "Project2",
                    href: "#",
                },
                {
                    title: "Home Interior Design",
                    srcset: {
                        webp: require("../assets/images/project/p-3.webp"),
                        jpg: require("../assets/images/project/p-3.jpg")
                    },
                    src: require("../assets/images/project/p-3.jpg"),
                    alt: "Project3",
                    href: "#",
                },
                {
                    title: "Home Interior Design",
                    srcset: {
                        webp: require("../assets/images/project/p-4.webp"),
                        jpg: require("../assets/images/project/p-4.jpg")
                    },
                    src: require("../assets/images/project/p-4.jpg"),
                    alt: "Project4",
                    href: "#",
                },
                {
                    title: "Home Interior Design",
                    srcset: {
                        webp: require("../assets/images/project/p-5.webp"),
                        jpg: require("../assets/images/project/p-5.jpg")
                    },
                    src: require("../assets/images/project/p-5.jpg"),
                    alt: "Project5",
                    href: "#",
                },
                {
                    title: "Home Interior Design",
                    srcset: {
                        webp: require("../assets/images/project/p-3.webp"),
                        jpg: require("../assets/images/project/p-3.jpg")
                    },
                    src: require("../assets/images/project/p-3.jpg"),
                    alt: "Project3",
                    href: "#",
                },
            ],
        }),
    });
</script>

<style lang="css">
    .carousel__pagination-button {
        background-color: rgba(95, 92, 92, 0.3);
        width: 15px;
        height: 10px;
    }

    .carousel__pagination-button--active {
        background-color: rgba(109, 91, 94, 0.95);
        width: 20px;
        height: 10px;
    }

    .carousel__pagination {
        margin-top: 35px;
    }

    @media (max-width: 767px) {
        .project-area {
            padding-top: 75px;
            padding-bottom: 80px;
        }
    }

    .single-project {
        position: relative;
    }

    .single-project .project-image {
        position: relative;
    }

    .single-project .project-image::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background: white;
        background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 50%, #eb3656 100%);
        background: -o-linear-gradient(rgba(255, 255, 255, 0) 50%, #eb3656 100%);
        background: linear-gradient(rgba(255, 255, 255, 0) 50%, #eb3656 100%);
        opacity: 0;
        -webkit-transition: all 0.4s ease-out 0s;
        -moz-transition: all 0.4s ease-out 0s;
        -ms-transition: all 0.4s ease-out 0s;
        -o-transition: all 0.4s ease-out 0s;
        transition: all 0.4s ease-out 0s;
    }

    .single-project .project-content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 35px 5px;
        -webkit-transform: translateY(100%);
        -moz-transform: translateY(100%);
        -ms-transform: translateY(100%);
        -o-transform: translateY(100%);
        transform: translateY(100%);
        -webkit-transition: all 0.4s ease-out 0s;
        -moz-transition: all 0.4s ease-out 0s;
        -ms-transition: all 0.4s ease-out 0s;
        -o-transition: all 0.4s ease-out 0s;
        transition: all 0.4s ease-out 0s;
        opacity: 0;
    }

    .single-project .project-content .project-title {
        font-size: 22px;
        font-weight: 600;
        font-family: "Barlow", sans-serif;
        position: relative;
        color: #fff;
        margin-left: 60px;
    }

    .single-project .project-content .project-title::before {
        position: absolute;
        content: "";
        width: 50px;
        height: 1px;
        background-color: #fff;
        top: 50%;
        left: -60px;
    }

    .single-project:hover .project-image::before {
        opacity: 1;
    }

    .single-project:hover .project-content {
        opacity: 1;
        -webkit-transform: translateY(0%);
        -moz-transform: translateY(0%);
        -ms-transform: translateY(0%);
        -o-transform: translateY(0%);
        transform: translateY(0%);
    }

    .project-active .slick-dots {
        text-align: center;
        margin-top: 50px;
    }

    .project-active .slick-dots li {
        display: inline-block;
        margin: 0 7px;
    }

    .project-active .slick-dots li button {
        width: 25px;
        height: 15px;
        border-radius: 50px;
        border: 0;
        background-color: rgba(235, 54, 86, 0.7);
        -webkit-transition: all 0.4s ease-out 0s;
        -moz-transition: all 0.4s ease-out 0s;
        -ms-transition: all 0.4s ease-out 0s;
        -o-transition: all 0.4s ease-out 0s;
        transition: all 0.4s ease-out 0s;
        font-size: 0;
        cursor: pointer;
    }

    .project-active .slick-dots li.slick-active button {
        width: 35px;
        background-color: #eb3656;
    }
</style>
