<template>
  <!--====== SERVICES PART START ======-->

  <section id="service" class="services-area pt-125 pb-130 gray-bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="section-title text-center pb-20">
            <h5 class="sub-title mb-15">Our Services</h5>
            <h2 class="title">What We Do?</h2>
          </div>
          <!-- section title -->
        </div>
      </div>
      <!-- row -->
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-8">
          <div
            class="single-services text-center mt-30 wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay="0.4s"
          >
            <div class="services-icon">
              <i class="lni-paint-roller"></i>
            </div>
            <div class="services-content mt-15">
              <h4 class="services-title">Interior Design</h4>
              <p class="mt-20">
                Mauris aliquam, turpis sed mattis placerat, justo risus
                pellentesque quam, id finibus risus arcu eget neque.
              </p>
            </div>
          </div>
          <!-- single services -->
        </div>
        <div class="col-lg-4 col-md-6 col-sm-8">
          <div
            class="single-services text-center mt-30 wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay="0.8s"
          >
            <div class="services-icon">
              <i class="lni-blackboard"></i>
            </div>
            <div class="services-content mt-15">
              <h4 class="services-title">Design Consultancy</h4>
              <p class="mt-20">
                Mauris aliquam, turpis sed mattis placerat, justo risus
                pellentesque quam, id finibus risus arcu eget neque.
              </p>
            </div>
          </div>
          <!-- single services -->
        </div>
        <div class="col-lg-4 col-md-6 col-sm-8">
          <div
            class="single-services text-center mt-30 wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay="1.2s"
          >
            <div class="services-icon">
              <i class="lni-home"></i>
            </div>
            <div class="services-content mt-15">
              <h4 class="services-title">Residential Design</h4>
              <p class="mt-20">
                Mauris aliquam, turpis sed mattis placerat, justo risus
                pellentesque quam, id finibus risus arcu eget neque.
              </p>
            </div>
          </div>
          <!-- single services -->
        </div>
        <div class="col-lg-4 col-md-6 col-sm-8">
          <div
            class="single-services text-center mt-30 wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay="0.4s"
          >
            <div class="services-icon">
              <i class="lni-briefcase"></i>
            </div>
            <div class="services-content mt-15">
              <h4 class="services-title">Commercial Design</h4>
              <p class="mt-20">
                Mauris aliquam, turpis sed mattis placerat, justo risus
                pellentesque quam, id finibus risus arcu eget neque.
              </p>
            </div>
          </div>
          <!-- single services -->
        </div>
        <div class="col-lg-4 col-md-6 col-sm-8">
          <div
            class="single-services text-center mt-30 wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay="0.8s"
          >
            <div class="services-icon">
              <i class="lni-handshake"></i>
            </div>
            <div class="services-content mt-15">
              <h4 class="services-title">Hospitality Design</h4>
              <p class="mt-20">
                Mauris aliquam, turpis sed mattis placerat, justo risus
                pellentesque quam, id finibus risus arcu eget neque.
              </p>
            </div>
          </div>
          <!-- single services -->
        </div>
        <div class="col-lg-4 col-md-6 col-sm-8">
          <div
            class="single-services text-center mt-30 wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay="1.2s"
          >
            <div class="services-icon">
              <i class="lni-grow"></i>
            </div>
            <div class="services-content mt-15">
              <h4 class="services-title">Co-working Space Design</h4>
              <p class="mt-20">
                Mauris aliquam, turpis sed mattis placerat, justo risus
                pellentesque quam, id finibus risus arcu eget neque.
              </p>
            </div>
          </div>
          <!-- single services -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </section>

  <!--====== SERVICES PART ENDS ======-->
</template>

<script>
export default {
  name: "Service",
};
</script>