<template>
    <section id="testimonial" class="testimonial-area pt-130 pb-130">
        <div class="shape shape-one">
            <img :src="shape.src" alt="testimonial"/>
        </div>
        <div class="shape shape-tow">
            <img :src="shape.src" alt="testimonial"/>
        </div>
        <div class="shape shape-three">
            <img :src="shape.src" alt="testimonial"/>
        </div>
        <div class="container">
            <div class="testimonial-bg bg_cover pt-80 pb-80" style="">
                <div class="row">
                    <div
                            class="
              col-xl-4
              offset-xl-7
              col-lg-5
              offset-lg-6
              col-md-8
              offset-md-2
              col-sm-10
              offset-sm-1
            "
                    >
                        <div class="testimonial-active">
                            <Carousel :settings="settings" :breakpoints="breakpoints" :autoplay="7000"
                                      :wrap-around="true">
                                <Slide v-for="slide in sliders" :key="slide.alt">
                                    <div class="single-testimonial text-center">
                                        <div class="testimonial-image">
                                            <img :src="slide.src" :alt="slide.alt"/>
                                            <div class="quota">
                                                <i class="lni-quotation"></i>
                                            </div>
                                        </div>
                                        <div class="testimonial-content mt-20">
                                            <p>{{ slide.title }}</p>
                                            <h5 class="testimonial-name mt-15">{{ slide.name }}</h5>
                                            <span class="sub-title">{{ slide.stitle }}</span>
                                        </div>
                                    </div>
                                </Slide>
                                <template #addons>
                                    <Pagination></Pagination>
                                    <Navigation></Navigation>
                                </template>
                            </Carousel>
                        </div>
                        <!--  testimonial active -->
                    </div>
                </div>
                <!-- row -->
            </div>
            <!-- testimonial bg -->
        </div>
        <!-- container -->
    </section>
</template>

<script>
    // import "../assets/js/slick.min.js";
    import {defineComponent} from "vue";
    import {Carousel, Pagination, Slide} from "vue3-carousel";
    import "vue3-carousel/dist/carousel.css";

    export default defineComponent({
        name: "TestimonialSlider",
        components: {
            Pagination,
            Carousel,
            Slide,
        },
        data: () => ({
            // carousel settings
            settings: {
                itemsToShow: 1,
                snapAlign: "center"
            },
            // breakpoints are mobile first
            // any settings not specified will fallback to the carousel settings
            breakpoints: {
                // // 325px and up
                // 325: {
                //   itemsToShow: 1,
                //   snapAlign: "center",
                // },
                // // 700px and up
                // 700: {
                //   itemsToShow: 3.5,
                //   snapAlign: "center",
                // },
                // // 1024 and up
                // 1024: {
                //   itemsToShow: 5,
                //   snapAlign: "start",
                // },
            },
            shape: {
                src: require("../assets/images/testimonial/shape.png")
            },
            sliders: [
                {
                    title:
                        "Lorem ipsum dolor sit amet, ectetur adipiscing elit. Phasellus vel erat ces, commodo lectus eu, finibus diam. m ipsum dolor sit amet, ectetur.",
                    src: require("../assets/images/testimonial/t-1.jpg"),
                    alt: "Testimonial1",
                    name: "Fajar",
                    stitle: "Random Customer",
                },
                {
                    title:
                        "Lorem ipsum dolor sit amet, ectetur adipiscing elit. Phasellus vel erat ces, commodo lectus eu, finibus diam. m ipsum dolor sit amet, ectetur.",
                    src: require("../assets/images/testimonial/t-2.jpg"),
                    alt: "Testimonial2",
                    name: "Alina",
                    stitle: "Tesla Motor",
                },
                {
                    title:
                        "Lorem ipsum dolor sit amet, ectetur adipiscing elit. Phasellus vel erat ces, commodo lectus eu, finibus diam. m ipsum dolor sit amet, ectetur.",
                    src: require("../assets/images/testimonial/t-3.jpg"),
                    alt: "Testimonial3",
                    name: "Radjap",
                    stitle: "CEO, Alo",
                },
            ],
        }),
    });
</script>

<style lang="css" scoped>

    .bg_cover {
        background-image: url(../assets/images/testimonial/testimonial-bg.jpg);
        background-position: center center;
        background-size: auto;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
    }
    .bg_cover {
        background-image: url(../assets/images/testimonial/testimonial-bg.webp);
    }

    /*===========================
          7.TESTIMONIAL css
    ===========================*/
    .testimonial-area {
        position: relative;
    }

    @media (max-width: 767px) {
        .testimonial-area {
            padding-top: 80px;
            padding-bottom: 80px;
        }
    }

    .testimonial-area .shape {
        position: absolute;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        .testimonial-area .shape img {
            width: 325px;
        }
    }

    .testimonial-area .shape-one {
        top: 25px;
        left: 50px;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        .testimonial-area .shape-one {
            left: 20px;
        }
    }

    .testimonial-area .shape-tow {
        left: 150px;
        bottom: 50px;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        .testimonial-area .shape-tow {
            left: 70px;
        }
    }

    .testimonial-area .shape-three {
        right: 85px;
        bottom: 30px;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        .testimonial-area .shape-three {
            right: 35px;
        }
    }

    .testimonial-bg {
        position: relative;
        z-index: 9;
    }

    .testimonial-active {
        background-color: #eb3656;
        padding: 60px 30px;
    }

    @media (max-width: 767px) {
        .testimonial-active {
            padding: 30px 15px;
        }
    }

    .single-testimonial .testimonial-image {
        position: relative;
        display: inline-block;
    }

    .single-testimonial .testimonial-image img {
        border-radius: 50%;
    }

    .single-testimonial .testimonial-image .quota {
        position: absolute;
        bottom: 0;
        right: 0;
        display: inline-block;
    }

    .single-testimonial .testimonial-image .quota i {
        font-size: 18px;
        color: #eb3656;
        width: 30px;
        height: 30px;
        line-height: 28px;
        text-align: center;
        background-color: #fff;
        border: 2px solid #eb3656;
        border-radius: 50%;
    }

    .single-testimonial .testimonial-content p {
        font-size: 18px;
        color: #fff;
        font-family: "Hind", sans-serif;
    }

    .single-testimonial .testimonial-content .testimonial-name {
        font-family: "Barlow", sans-serif;
        font-size: 18px;
        color: #fff;
        font-weight: 600;
    }

    .single-testimonial .testimonial-content .sub-title {
        font-family: "Hind", sans-serif;
        font-size: 14px;
        color: #fff;
    }

    .testimonial-active .slick-dots {
        text-align: center;
        margin-top: 10px;
    }

    .testimonial-active .slick-dots li {
        display: inline-block;
        margin: 0 5px;
    }

    .testimonial-active .slick-dots li button {
        width: 18px;
        height: 15px;
        border-radius: 50px;
        border: 0;
        background-color: rgba(255, 255, 255, 0.5);
        -webkit-transition: all 0.4s ease-out 0s;
        -moz-transition: all 0.4s ease-out 0s;
        -ms-transition: all 0.4s ease-out 0s;
        -o-transition: all 0.4s ease-out 0s;
        transition: all 0.4s ease-out 0s;
        font-size: 0;
        cursor: pointer;
    }

    .testimonial-active .slick-dots li.slick-active button {
        background-color: #fff;
        width: 25px;
    }
</style>
