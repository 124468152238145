<template>
  <!--====== ABOUT PART START ======-->

  <section id="about" class="about-area pt-80 pb-130">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="about-image mt-50 clearfix">
            <div class="single-image float-left">
              <picture>
                <source srcset="../assets/images/about/about-1.webp" type="image/webp">
                <source srcset="../assets/images/about/about-1.png" type="image/png">
                <img src="../assets/images/about/about-1.png" alt="About" />
              </picture>
            </div>
            <!-- single image -->
            <div data-aos="fade-right" class="about-btn">
              <a class="main-btn" href="#"><span>27</span> Years Experience</a>
            </div>
            <div class="single-image image-tow float-right">
              <picture>
                <source srcset="../assets/images/about/about-2.webp" type="image/webp">
                <source srcset="../assets/images/about/about-2.png" type="image/png">
                <img src="../assets/images/about/about-2.png" alt="About" />
              </picture>
            </div>
            <!-- single image -->
          </div>
          <!-- about image -->
        </div>
        <div class="col-lg-6">
          <div class="about-content mt-45">
            <h4 class="about-welcome">About Us</h4>
            <h3 class="about-title mt-10">Reasons to choose</h3>
            <p class="mt-25">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages </p>
              <p>Was popularised in the 1960s with the release of Letraset
              sheets containing Lorem Ipsum passages, and more recently with
              desktop publishing software like Aldus PageMaker including
              versions of Lorem Ipsum.
            </p>
            <a class="main-btn mt-25" href="#">learn more</a>
          </div>
          <!-- about content -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </section>

  <!--====== ABOUT PART ENDS ======-->
</template>


<script>
export default {
  name: "About"
};
</script>
<style lang="css" scoped>
p {
  margin: 20px 5px 20px;
}
p::first-letter {
    font-size: 3em; /* Размер буквы */
    color: red; /* Цвет буквы */
    float: left; /* Выравнивание по левому краю */
    margin: 0 4px 2px 0; /* Отступы вокруг буквы */
   }
</style>
