<template>
  <!--====== CONTACT PART START ======-->
  <section id="contact" class="contact-area pt-125 pb-130 gray-bg">
    <div class="container">
      <Modal ref="refsModal" :title="modal.title" :message="modal.message" />
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="section-title text-center pb-20">
            <h5 class="sub-title mb-15">Contact us</h5>
            <h2 class="title">Get In touch</h2>
          </div>
          <!-- section title -->
        </div>
      </div>
      <!-- row -->
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <form class="contact-form">
            <div id="contact-form"></div>
            <div class="row">
              <div class="col-md-6">
                <div class="single-form form-group">
                  <input
                    :class="[
                      'form-control',
                      v$.ContactForm.name.$error ? 'is-invalid' : 'is-valid',
                    ]"
                    type="text"
                    v-model.trim="ContactForm.name"
                    placeholder="Your Name"
                    @blur="v$.ContactForm.name.$touch()"
                    @focus="v$.ContactForm.name.$touch()"
                    @keyup="updateData(ContactForm.name)"
                  />
                  <transition name="fadeIn">
                    <div
                      class="invalid-feedback"
                      v-if="v$.ContactForm.name.$error"
                      style="display: block"
                    >
                      <div
                        v-for="(error, index) of v$.ContactForm.name.$errors"
                        :key="index"
                      >
                        {{ error.$message }}
                      </div>
                    </div>
                  </transition>
                </div>
                <!-- single form -->
              </div>
              <div class="col-md-6">
                <div class="single-form form-group">
                  <input
                    class="form-control"
                    :class="[
                      v$.ContactForm.email.$error ? 'is-invalid' : 'is-valid',
                    ]"
                    type="email"
                    v-model.trim="ContactForm.email"
                    placeholder="Your Email"
                    @blur="v$.ContactForm.email.$touch()"
                    @focus="v$.ContactForm.email.$touch()"
                    @keyup="v$.ContactForm.email.$touch()"
                  />
                  <transition name="fadeIn">
                    <div
                      class="invalid-feedback"
                      v-if="v$.ContactForm.email.$error"
                      style="display: block"
                    >
                      <div
                        v-for="(error, index) of v$.ContactForm.email.$errors"
                        :key="index"
                      >
                        {{ error.$message }}
                      </div>
                    </div>
                  </transition>
                </div>
                <!-- single form -->
              </div>
              <div class="col-md-6">
                <div class="single-form form-group">
                  <input
                    :class="[
                      'form-control',
                      v$.ContactForm.subject.$error ? 'is-invalid' : 'is-valid',
                    ]"
                    type="text"
                    v-model.trim="ContactForm.subject"
                    placeholder="Subject"
                    @blur="v$.ContactForm.subject.$touch()"
                    @focus="v$.ContactForm.subject.$touch()"
                    @keyup="v$.ContactForm.subject.$touch()"
                  />
                  <transition name="fadeIn">
                    <div
                      class="invalid-feedback"
                      v-if="v$.ContactForm.subject.$error"
                      style="display: block"
                    >
                      <div
                        v-for="(error, index) of v$.ContactForm.subject.$errors"
                        :key="index"
                      >
                        {{ error.$message }}
                      </div>
                    </div>
                  </transition>
                </div>
                <!-- single form -->
              </div>
              <div class="col-md-6">
                <div class="single-form form-group">
                  <input
                    :class="[
                      'form-control',
                      v$.ContactForm.phone.$error ? 'is-invalid' : 'is-valid',
                    ]"
                    type="text"
                    v-model.trim="ContactForm.phone"
                    placeholder="Phone"
                    @blur="v$.ContactForm.phone.$touch()"
                    @focus="v$.ContactForm.phone.$touch()"
                    @keyup="v$.ContactForm.phone.$touch()"
                  />
                  <transition name="fadeIn">
                    <div
                      class="invalid-feedback"
                      v-if="v$.ContactForm.phone.$error"
                      style="display: block"
                    >
                      <div
                        v-for="(error, index) of v$.ContactForm.phone.$errors"
                        :key="index"
                      >
                        {{ error.$message }}
                      </div>
                    </div>
                  </transition>
                </div>
                <!-- single form -->
              </div>
              <div class="col-md-12">
                <div class="single-form form-group required">
                  <textarea
                    :class="[
                      'form-control',
                      v$.ContactForm.message.$error ? 'is-invalid' : 'is-valid',
                    ]"
                    placeholder="Your Mesaage"
                    v-model="ContactForm.message"
                    @blur="v$.ContactForm.message.$touch()"
                    @focus="v$.ContactForm.message.$touch()"
                    @keyup="v$.ContactForm.message.$touch()"
                  >
                  </textarea>
                  <transition name="fadeIn">
                    <div
                      class="invalid-feedback"
                      v-if="v$.ContactForm.message.$error"
                      style="display: block"
                    >
                      <div
                        v-for="(error, index) of v$.ContactForm.message.$errors"
                        :key="index"
                      >
                        {{ error.$message }}
                      </div>
                    </div>
                  </transition>
                </div>
                <!-- single form -->
              </div>
              <p class="form-message"></p>
              <div class="col-md-12">
                <div class="single-form form-group text-center">
                  <button
                    id="btn-send-message"
                    type="button"
                    class="main-btn"
                    @click="sendForm()"
                  >
                    send message
                  </button>
                </div>
                <!-- single form -->
              </div>
            </div>
            <!-- row -->
          </form>
        </div>
        <!-- row -->
      </div>
    </div>
    <!-- row -->
    <!-- container -->
  </section>
  <!--====== CONTACT PART ENDS ======-->
</template>

<script>
import * as Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import useVuelidate from "@vuelidate/core";
import Modal from "@/components/Modal.vue";

import {
  required,
  email,
  minLength,
  maxLength,
  between,
  helpers,
} from "@vuelidate/validators";

const contact = Vue.createApp();
// const alpha = helpers.regex(/[0-9+ ,.!#?$%а-яёА-ЯЁІіЇїЄє' ]+$/iu);
const alpha = helpers.regex(/[а-яёА-ЯЁІіЇїЄє' ]+$/g);
const phoneNumber = helpers.regex(/^\+?3?8?(0[5-9][0-9]\d{7})$/);
const emails = helpers.regex(
  /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-z]{2,3})$/
);

contact.use(VueAxios, axios);

const querystring = require("querystring");

export default {
  name: "ContactForm",
  setup: () => ({ v$: useVuelidate() }),
  components: { Modal },
  data() {
    return {
      modal: {
        title: "",
        message: "",
      },
      sent: false,
      $er: "",
      ContactForm: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      },
    };
  },
  validations() {
    return {
      ContactForm: {
        name: {
          required: helpers.withMessage("This field cannot be empty", required),
          minLengthValue: minLength(2),
          maxLengthValue: maxLength(15),
          $lazy: true,
          alpha: helpers.withMessage("Cyrilic only", alpha),
        },
        email: {
          required: helpers.withMessage("This field cannot be empty", required),
          emails: helpers.withMessage(
            "Value is not a valid email address",
            emails
          ),
        },
        subject: {
          required: helpers.withMessage("This field cannot be empty", required),
          alpha: helpers.withMessage("Cyrilic only", alpha),
          $lazy: true,
          minLengthValue: minLength(5),
          maxLengthValue: maxLength(15),
        },
        phone: {
          phoneNumber: helpers.withMessage(
            "Value is not a valid mobile phone number, format - +380XXXXXXXXX",
            phoneNumber
          ),
          required: helpers.withMessage("This field cannot be empty", required),
          $lazy: true,
        },
        message: {
          //   betweenValue: between(5, 215),
          minLengthValue: minLength(20),
          maxLengthValue: maxLength(850),
          required: helpers.withMessage("This field cannot be empty", required),
          $lazy: false,
        },
      },
    };
  },
  methods: {
    async sendForm() {
      let isFormCorrect = await this.v$.$validate();
      var btn_send_messge = document.getElementById("btn-send-message");
      if (isFormCorrect) {
        await axios
          .post(
            "/vue-cli/bootstrap-vue/dist/mail.php",
            querystring.stringify(this.ContactForm)
          )
          .then((res) => {
            this.sent = true;
            this.showModal();
            this.modal.title = "Success";
            this.modal.message = "Message sent successfully";
            btn_send_messge.setAttribute("disabled", "disabled");
          });
      } else {
        this.showModal();
        this.modal.title = "Failure";
        this.modal.message = "All required fields must be filled";
      }
    },
    updateData(data) {
      this.ContactForm.name = data.replace(/^[0-9-/+ ,.!#?$%a-zA-Z]+$/, "");
    },
    showModal() {
      this.$refs.refsModal.moodalShow();
    },
  },

  mounted() {
    let validClass = document.querySelectorAll(".is-valid");
    validClass.forEach((valid) => {
      valid.classList.remove("is-valid");
    });
  },
};
</script>

<style lang="css">
.is-valid {
  border: 1px solid #198754 !important;
}
.is-invalid {
  border: 1px solid #dc3545 !important;
}
.modal {
  top: 10%;
  background: rgb(125, 137, 137);
  background: linear-gradient(
    0deg,
    rgba(125, 137, 137, 0.2416049228625351) 51%,
    rgba(135, 133, 128, 1) 100%
  );
}
.modal-content {
  -webkit-box-shadow: 0px 7px 13px -2px rgba(34, 60, 80, 0.12);
  -moz-box-shadow: 0px 7px 13px -2px rgba(34, 60, 80, 0.12);
  box-shadow: 0px 7px 13px -2px rgba(34, 60, 80, 0.12);
}
.modal-open {
  overflow: hidden;
}
@media (max-width: 767px) {
  .contact-area {
    padding-top: 75px;
    padding-bottom: 80px;
  }
}

.form-group {
  margin: 0;
}

p.form-message.success,
p.form-message.error {
  font-size: 16px;
  color: #333;
  background: #ddd;
  padding: 10px 15px;
  margin-left: 15px;
  margin-top: 15px;
}

p.form-message.error {
  color: #f00;
}

.contact-form .single-form {
  margin-top: 30px;
}
.contact-form .single-form textarea,
.contact-form .single-form input {
  width: 100%;
  height: 60px;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 0 30px;
  background-color: #fff;
  font-family: "Hind", sans-serif;
  font-size: 16px;
}
.contact-form .single-form textarea {
  height: 160px;
  padding-top: 20px;
  resize: none;
}
.contact-form .single-form textarea::placeholder,
.contact-form .single-form input::placeholder {
  opacity: 1;
  color: #999;
}

.list-unstyled li {
  font-size: 13px;
  margin-left: 2px;
  margin-top: 5px;
  color: #f00;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.fadeIn-enter-active,
.fadeIn-leave-active {
  transition: opacity 0.8s ease;
}
.fadeIn-enter-from,
.fadeIn-leave-to {
  opacity: 0;
}
</style>